(function() {
    "use strict";
    angular.module("answerReveal")
        .directive("answerReveal", [answerReveal])

    function answerReveal() {
        return {
            restrict: 'E',
            controller: 'answerRevealController as answerReveal',
            bindToController: true,
            replace: true,
            templateUrl: "components/round-answer-reveal/answers-reveal.template.html",
            scope: {
                answers: '=answers',
            }
        }
    }
})();
