(function() {
    "use strict";
    angular.module("match-players")
        .controller("matchPlayersController", ['match', matchPlayersController]);

    function matchPlayersController(match) {
        var matchPlayers = this;
        //If wanting to display players in turn order, need to reference round data
        matchPlayers.players = match.data.players;
        // console.log('c', match.data);
    }

})();
