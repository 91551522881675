(function() {
    "use strict";

    angular.module("answerReveal")
        .controller("answerRevealController", ['$scope', 'answerStamp', "roundService", "authService", "round", answerRevealController]);

    function answerRevealController($scope, answerStamp, roundService, authService, round) {
        let answerReveal = this;

        answerReveal.data = {
            revealOptions: {}
        };

        function initialize() {
            $scope.$on('round.updateResult', function(event, data) {
                updateAnswers(data);
            });

            $scope.$on('round.revealResult', function(event, data) {
                updateAnswers(data);
            });

            populateRevealOptions(answerReveal.answers);
        }

        answerReveal.selectAnswer = function(answer) {
            roundService.revealAnswer(round.data.game_id, round.data.round_id, answer.sequence);
            answer.status = 'revealed';
        };

        function populateRevealOptions(answers) {
            answers.forEach(function(answer, index) {
                answerReveal.data.revealOptions[answer.answer_text] = answerStamp.create({
                    text: answer.answer_text,
                    sequence: answer.sequence,
                    active: answer.active,
                    status: (answer.active == 1) ? 'selected' : 'incorrect' //TODO, shouldn't be ternary several options, but only matters if we replace list every turn rather than update
                });

            });
        }

        function updateAnswers(data) {
            if (data.player_id == authService.data.user.player_id) {
                //Was player's own turn, update reveal result
                answerReveal.data.revealOptions[data.player_answer_text].setStatus(data.correct ? 'correct' : 'incorrect');
            }

            if (data.hasOwnProperty('stolen_from')) {
                if (data.stolen_from.hasOwnProperty(authService.data.user.player_id)) {
                    answerReveal.data.revealOptions[data.player_answer_text].setStatus('stolen');
                    console.log('stolen from us');
                }

                //TODO display details about steals from other players
            }
        }


        initialize()
    }

})();
