(function() {
    "use strict";
    angular.module("question")
        .directive("question",
            [roundQuestion])

    function roundQuestion() {
        return {
            restrict: 'E',
            controller: 'questionController as question',
            bindToController: true,
            replace: true,
            templateUrl: 'components/round-question/question.template.html',
            scope: {
                question: '=round'
            }
        }
    }
})();



