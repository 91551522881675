(function() {
    "use strict";
    angular.module("question")
        .controller("questionController",
            ['$element', questionController]);

    function questionController($element) {
        let question = this;
    }
    
})();



