(function() {
    "use strict";

    angular.module("roundReporter")
        .controller("roundReporterController", ['$scope', 'round', 'match', roundReporterController]);

    function roundReporterController($scope, round, match) {
        let roundReporter = this;

        roundReporter.currentPlayer = null;

        console.log(match.data);

        $scope.$watch('data.currentPlayer', function(currentPlayer) {
            if (currentPlayer) {
                roundReporter.currentPlayer = match.data.players[currentPlayer];
                console.log(roundReporter.currentPlayer);
            }
        });


        // console.log(this);
        // console.log(round);

        // roundReporter.data = {
        //     stage: 'answer'
        // }

    }

})();
