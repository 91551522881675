(function() {
    "use strict";
    angular.module("answerSelect")
        .controller("answerSelectController",
            ['$scope', 'answerStamp', answerSelectController]);

    
    function answerSelectController($scope, answerStamp) {
        let answerSelect = this;

        answerSelect.data = {
            selectionCount: 0,
            selectedAnswers: [],
            answers: {}
        };

        function initialize() {
            populateAnswers(answerSelect.answers);
        }

        answerSelect.selectAnswer = function(answer) {

            if (answer.status === 'available' && answerSelect.data.selectionCount < answerSelect.selectionLimit) {
                answer.status = 'selected';
            } else if (answer.status === 'selected') {
                answer.status = 'available';
            }

            answerSelect.data.selectedAnswers = updateSelectedAnswers();
            answerSelect.data.selectionCount = answerSelect.data.selectedAnswers.length;

            $scope.$emit('answer-selection', {answers: answerSelect.data.selectedAnswers});

        };

        function populateAnswers(answers) {
            answers.forEach(function(answer, index) {
                answerSelect.data.answers[answer] = answerStamp.create({
                    index: index,
                    text: answer
                });
            });
        }

        function updateSelectedAnswers() {
            var selectedAnswers = [];

            // TODO - this is stupid, just user answers.filter to get all selected answers
            // Can't do that with object, not an array

            for(var answer in answerSelect.data.answers) {

                if (!answerSelect.data.answers.hasOwnProperty(answer)) {
                    continue;
                }

                answer = answerSelect.data.answers[answer];

                if (answer.status === 'selected') {
                    selectedAnswers.push(answer.text);
                }

            }

            return selectedAnswers;
        }


        initialize()
    }


})();



