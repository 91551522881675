(function() {
    "use strict";
    angular.module('game-mode-selection')
        .controller("gameModeSelectionController", ["$scope", "outsmart_api", "room",
            gameModeSelectionController
        ]);

    function gameModeSelectionController($scope, outsmart_api, room) {

        const gameModeSelection = this;
        gameModeSelection.setGameMode = setGameMode;

        $scope.$on('login.waitResult', wait);

        /**
         * Each room is associated with a specific game mode, we request to join the hardcorded room for the selected mode
         * @param mode
         */
        function setGameMode(mode) {
            gameModeSelection.mode = mode;
        };

        function wait() {
            outsmart_api.updateRequestTracker(['login.wait']);
        }

    }
})();
