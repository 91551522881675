(function() {
    "use strict";
    angular.module("game-mode-selection")
        .component("gameModeSelection", {
            controller: 'gameModeSelectionController as gameModeSelection',
            templateUrl: "components/game-mode-selection/game-mode-selection.template.html",
            bindings: {
                mode: '='
            }
        });
})();
