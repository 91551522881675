(function() {
    "use strict";
    angular.module('matchModule')
        .controller("matchController",
            ["$scope", "$state", "outsmart_api", "room", "match", "round", "updater", matchController]);

    function matchController($scope, $state, outsmart_api, room, match, round, updater) {

        var listeners = {
            gameSleep: $scope.$on('game.sleep', gameSleep), // no relevant data to update
            roundStart: $scope.$on('game.roundStart', function(event, data) {
                roundStart(data);
            }),
            rematchOffer: $scope.$on('game.rematchOffer', function(event, data) {
                rematchOffer(data);
            })
        };

        let updaterInstance = updater.start('match');

        $scope.$on(
            "$destroy",
            function destroy() {
                updater.stop(updaterInstance);
            }
        );

        $scope.match = match.data;

        function gameSleep() {
            outsmart_api.updateRequestTracker(['game.wait']);
        }

        function roundStart(data) {
            outsmart_api.updateRequestTracker(['game.wait']);
            round.update(data);
            $state.go('round');
        }

        function rematchOffer(data) {
            outsmart_api.updateRequestTracker(['game.wait']);

            //"game_id": "13351",
            //    "timeout": 40,
            //    "winning_player": 6146,
            //    "new_game_id": "13356",
            //    "callback_time": "2"

            if (confirm("Rematch?")) {
                room.set('rematch', data);
                $state.go('matchmaking');
            } else {
                $state.go('game-mode-selection');
            }
        }
    }

})();
