(function() {
    "use strict";
    angular.module("menuModule")
        .directive("menuToggleDirective", function() {

            return {
                restrict: 'E',
                replace: true,
                templateUrl: "components/menu/menu-toggle.template.html",
                link: function($scope, elem, att) {
                    $scope.menuActive = false;

                    $scope.toggleMenu = function toggleMenu() {
                        $scope.menuActive = !$scope.menuActive;
                    };
                }
            };
        });

})();
