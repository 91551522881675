(function() {
    "use strict";
    angular.module("roundModule").directive("answersEntryDirective", function() {
        return {
            restrict: 'A',
            replace: true,
            //controller: "menuController",
            templateUrl: "components/round-answer-entry/answers-entry.template.html"
        };
    });
})();