(function() {
    "use strict";

    angular.module("outsmart-services").service('room', ["$sessionStorage", roomService]);

    function roomService($sessionStorage) {

        if (!$sessionStorage.hasOwnProperty('room')) {
            $sessionStorage.room = {};
        }

        return {
            data: $sessionStorage.room,

            update: function(data) {
                this.set('room_id', data.room_id);
                this.set('difficulty', data.difficulty);
                this.set('display_name', data.display_name);
                this.set('playersQueued', data.players.length);
            },
            set: function(field, value) {
                if (typeof value !== 'undefined') {
                    this.data[field] = value;
                }
            },
            unset: function(field) {
                delete this.data[field];
            }
        };
    }


})();
