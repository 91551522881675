(function() {
    "use strict";
    angular.module('roundModule').config(['$stateProvider', function($stateProvider) {
        $stateProvider.state("round", {
            url: "/round",
            templateUrl: "components/round/round.template.html",
            controller: "roundController"
        });
    }]);
})();
