(function() {
    "use strict";
    angular.module('matchmakingModule')
        .controller("matchmakingController", ["$scope", "$state", "outsmart_api", 'outsmartConfig', "room", "match", "updater",
            matchmakingController
        ]);

    function matchmakingController($scope, $state, outsmart_api, outsmartConfig, room, match, updater) {

        const matchmaking = this;
        matchmaking.joinRoom = joinRoom;

        // const STATUS = {
        //   FINDING
        // };

        matchmaking.state = {
            mode: 'normal',
            status: ''
        };

        $scope.$on('room.joinResult', function(event, data) {
            /* When a room has been joined / game mode has been selected, progress to matchmaking mode */
            /* TODO - Add handling for failed result */
            room.update(data);
            updater.stop(updaterInstance);
            updaterInstance = updater.start('matchmaker');
        });

        $scope.$on('room.updateResult', receivedRoomUpdate);
        $scope.$on('game.cancel', () => outsmart_api.updateRequestTracker(['room.update']));
        $scope.$on('game.offer', (event, data) => receivedGameOffer(data));
        $scope.$on('game.start', (event, data) => gameStart(data));
        $scope.$on('game.joinResult', gameJoined);

        let updaterInstance = updater.start('wait');

        $scope.$on(
            "$destroy",
            function destroy() {
                updater.stop(updaterInstance);
            }
        );



        function roomJoined() {
            $scope.room = room.data;

            updater.start('matchmaker');

            if (room.data.rematch) {
                // User confirmed a rematch, game is ready to try joining
                match.data.game_id = room.data.rematch.new_game_id;
                joinGame(room.data.rematch.new_game_id);
                room.unset('rematch');
            } else {
                joinQueue();
            }
        }

        function joinRoom(mode) {
            outsmart_api.post({
                "room.joinRoom": {
                    room_id: outsmartConfig.defaultRooms[mode]
                }
            });
        };

        /**
         * Requests to join the queue of the room, indicating the player is ready to be offered a game
         */
        function joinQueue() {
            outsmart_api.post({
                'room.joinQueue': {}
            });
        }

        /**
         * Game offer received, must be accepted or rejected within timeframe indicated by timeout property
         * http://outsmart.scaleengine.net/dev/apidocs/outsmart/outsmart_api_response_docs/game_offer.html
         */
        function receivedGameOffer(data) {
            outsmart_api.post({
                'game.accept': {
                    game_token: data.game_token
                }
            });
        }

        function receivedRoomUpdate(event, data) {
            room.update(data);
            outsmart_api.updateRequestTracker(['room.update']);
        }

        function gameStart(data) {
            outsmart_api.updateRequestTracker(['room.update']);
            match.update(data);
            joinGame(data.game_id);
        }

        function joinGame(gameId) {
            outsmart_api.post({
                'game.join': {
                    game_id: gameId
                }
            });
        }

        function gameJoined(event, data) {

            //match.update(data); //this data is less thorough then game.start response, should not override everything
            $state.go('match');
            //c.data.game = data;

            //c.data.room.players.length = 0;
            //
            //c.data.room.players = c.data.game.players;
            //
            //c.data.room.players.forEach(function(player, index, array) {
            //    player.score = 0;
            //});
        }

        $scope.room = room.data;

        if (room.data.rematch) {
            // User confirmed a rematch, game is ready to try joining
            match.data.game_id = room.data.rematch.new_game_id;
            joinGame(room.data.rematch.new_game_id);
            room.unset('rematch');
        } else {
            joinQueue();
        }

    }

})();
