(function() {
    "use strict";

    angular.module("roundReporter")
        .directive("roundReporter", [roundReporter])

    function roundReporter() {
        return {
            restrict: 'E',
            controller: 'roundReporterController as roundReporter',
            bindToController: true,
            replace: true,
            templateUrl: 'components/round-reporter/reporter.template.html'
        }
    }

})();
