(function() {
    "use strict";

    /**
     * @name outsmart.containerController
     * @extends outsmart.rootScope
     */
    angular.module('outsmart').controller("containerController", ["$scope", containerController]);

    function containerController($scope) {

    }
})();
