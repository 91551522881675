(function() {
    "use strict";

    angular.module("outsmart-services").service('match', ["$sessionStorage", matchService]);

    function matchService($sessionStorage) {

        if (!$sessionStorage.hasOwnProperty('match')) {
            $sessionStorage.match = {};
        }

        return {
            data: $sessionStorage.match,

            update: function(data) {
                var self = this;

                self.data.game_id = data.game_id;

                self.data.players = {};

                data.players.forEach(function(player) {
                    player.score = 0;
                    self.data.players[player.player_id] = player;
                });

            },
            set: function(field, value) {
                if (typeof value !== 'undefined') {
                    this.data[field] = value;
                }
            },
            unset: function(field) {
                delete this.data[field];
            }
        };
    }
})();
