(function() {
    "use strict";

    angular.module("outsmart-services")
        .service('authService', ['outsmart_api',
            '$sessionStorage', authService]);

    function authService(outsmart_api, $sessionStorage) {

        if (!$sessionStorage.hasOwnProperty('user')) {
            $sessionStorage.user = {
                player_id: null,
                cookie: null
            }
        }

        return {
            data: {
                user: $sessionStorage.user
            },
            login: function(credentials) {
                return outsmart_api.post({
                        "login.outsmart": credentials
                    }
                )
            },
            loginSuccess: function(session) {
                this.data.user.player_id = session.player_id;
                this.data.user.cookie = session.cookie;
            },
            register: function(credentials) {
                return outsmart_api.post({
                    "login.register": credentials
                });
            },
            logout: function() {
                delete this.data.user.player_id;
                delete this.data.user.cookie;

                return outsmart_api.post({
                    "login.logout": {}
                });
            },
            isAuthenticated: function isAuthenticated() {
                return this.data.user.player_id;
            }
        };

    }
})();

