(function() {
    "use strict";
    angular.module("answerSelect")
        .directive("answerSelect",
            [answerSelect])

    function answerSelect() {
        return {
            restrict: 'E',
            controller: 'answerSelectController as answerSelect',
            bindToController: true,
            replace: true,
            templateUrl: "components/round-answer-select/answers-select.template.html",
            scope: {
                answers: '=answers',
                selectionLimit: '=limit'
            }
        }
    }

})();



