(function() {
    "use strict";
    angular.module('matchmakingModule')
        .config(['$stateProvider', config]);

    function config($stateProvider) {
        $stateProvider.state("matchmaking", {
            url: "/matchmaking",
            templateUrl: "components/matchmaking/matchmaking.template.html",
            controller: "matchmakingController as matchmaking"
        });
    }
})();
