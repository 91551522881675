(function() {
    "use strict";

    angular.module("outsmart-services")
        .service("outsmart_api", ['$rootScope', "outsmartConfig", '$http', outsmartAPI]);

    function outsmartAPI($rootScope, outsmartConfig, $http) {
        var api = {};

        api.pendingRequestsTracker = {
            //Used to track and avoid repeats of repeating update requests
        };

        api.updateRequestTracker = function(events) {
            events.forEach(function(event) {
                if (api.pendingRequestsTracker.hasOwnProperty(event)) {
                    delete api.pendingRequestsTracker[event];
                }
            });
        };

        api.post = function post(data, track) {

            if (track) {
                for(var event in data) {
                    if (data.hasOwnProperty(event)) {

                        if (api.pendingRequestsTracker.hasOwnProperty(event)) {
                            //If event is in the tracker, response has not been received from previous request, skip new request.
                            return false;
                        }

                        //TODO update to store timestamp allowing cancellation after time has elapsed, or create timeouts
                        api.pendingRequestsTracker[event] = true;
                    }
                }
            }

            console.log('REQUEST', JSON.stringify(data, null, 4));
            return $http.post(outsmartConfig.server.endpoint, data).then(apiResponse);
        };

        function apiResponse(response) {
            var data = response.data;
            console.log('RESPONSE', JSON.stringify(data, null, 4));

            for(var event in data) {
                if (data.hasOwnProperty(event)) {
                    $rootScope.$broadcast(event, data[event]);
                }
            }

            return data;
        }

        return api;
    }
})();
