(function() {
    "use strict";
    angular.module("outsmart").constant('outsmartConfig', {
        globals: {
            'messageMap': {
                '10049': 'reveal-taken',
                '10050': 'off-turn-reveal'
            }
        },
        defaultLobby: 11,
        defaultRooms: {
            easy: 1,
            normal: 2,
            expert: 3
        },
        'game-modes': {
            easy: {
                difficulty: 1,
                speed: 7, //15,7,5
                length: 2
            },
            normal: {
                difficulty: 2,
                speed: 7,
                length: 2
            },
            expert: {
                difficulty: 3,
                speed: 7,
                length: 2
            }
        },
        updateIntervals: {
            matchmaker: 3000,
            match: 2000,
            round: 2000,
            wait: 10000
        },
        //localize: {
        //    default_language: 'en',
        //    path: 'assets/localization/',
        //    ext: '.lang.json'
        //},
        'server': {
            endpoint: '//outsmart.scaleengine.net/dev/api/endpoint.php?mode=json',
            //endpoint: '//outsmart.scaleengine.net/qa/api/endpoint.php?mode=json',
            lobby_id: 11,
            timeout: 60000
        }
    });
})();