(function() {
    "use strict";

    angular.module("outsmart-services").service('round', ["$sessionStorage", roundService]);

    function roundService($sessionStorage) {

        if (!$sessionStorage.hasOwnProperty('round')) {
            $sessionStorage.round = {};
        }

        return {
            data: $sessionStorage.round,

            update: function(data) {
                this.data.game_id = data.game_id;
                this.data.round_id = data.round_id;
                this.data.planned_rounds = data.planned_rounds;
                this.data.question_text = data.question_text;
                this.data.question_author = data.question_author;
                this.data.possible_answers = data.possible_answers;
                this.data.answer_count = data.answer_count;
                this.data.answer_time = data.answer_time;
                this.data.reveal_time = data.reveal_time;
                this.data.first_player = data.first_player;
                this.data.players = data.players;
                this.data.answers = data.answers;
            },
            set: function(field, value) {
                if (typeof value !== 'undefined') {
                    this.data[field] = value;
                }
            },
            unset: function(field) {
                delete this.data[field];
            }
        };

    }

})();
