(function() {
    "use strict";
    angular.module('matchModule').config(['$stateProvider', function($stateProvider) {
        $stateProvider.state("match", {
            url: "/match",
            templateUrl: "components/match/match.template.html",
            controller: "matchController"
        });
    }]);
})();

