(function() {
    "use strict";
    angular.module('score').config(['$stateProvider', function($stateProvider) {
        $stateProvider.state("score", {
            url: "/score",
            templateUrl: "components/score/score.template.html",
            controller: "scoreController"
        });
    }]);
})();
