(function() {
    "use strict";
    angular.module('roundModule')
        .controller("roundController", ["$scope", "$state", "authService",
            "outsmart_api", "room", "match", "round", 'roundService', "answerStamp", "updater",
            'toastr', roundController]);

    function roundController($scope, $state, authService, outsmart_api, room,
                             match, round, roundService, answerStamp, updater, toastr) {

        initialize();

        var listeners = {
            roundUpdate: $scope.$on('round.updateResult', roundUpdate),
            roundSleep: $scope.$on('round.sleep', roundSleep),
            answerResult: $scope.$on('round.answerResult', answerResult),
            revealResult: $scope.$on('round.revealResult', revealResult),
            roundEnd: $scope.$on('round.end', roundEnd)
        };

        function initialize() {
            console.log('Round Initialize');
            //console.log('Room:', room.data, 'Match:', match.data, 'Round:', round.data);
            //console.log('Round:', round.data);

            $scope.answers = {};
            $scope.revealOptions = {};

            $scope.room = room.data;
            $scope.match = match.data;
            $scope.round = round.data;

            $scope.state = {
                answersSubmitted: false //keeps track of answer submission, as both submit and timeout will return round.answerResult, and can not tell them apart
            };

            $scope.data = {
                isPlayersTurn: false,
                selectedAnswers: [],
                currentPlayer: null, //id of player who's turn it is
                timeRemaining: round.data.answer_time, //TODO - page reload will restart timer from initial value, need to store absolute time and calc difference, or update cookies
                revealTimer: 0,
                stage: 'answer' //reveal
            };

            $scope.callbackTimer = {
                finished: function() {
                    if ($scope.data.stage === 'answer') {
                        $scope.submitAnswers();
                        toastr.warning('Out of Time! Selections submitted');
                    }
                }
            };

            $scope.$on(
                "$destroy",
                function destroy() {
                    updater.stop(updaterInstance);
                }
            );

            $scope.$on(
                "answer-selection",
                function(event, args) {
                    $scope.data.selectedAnswers = args.answers;
                }
            );

            let updaterInstance = updater.start('round');
        }

        $scope.submitAnswers = function() {
            $scope.state.answersSubmitted = true;

            roundService.submitAnswers($scope.round.game_id, $scope.round.round_id, $scope.data.selectedAnswers);

            $scope.data.stage = 'waiting';
        };

        function updateProgress() {
            //if (selectedCount == selectedMax) {
            //    c.announce('submission-ready');
            //}
        }

        function requestRoundUpdate() {
            outsmart_api.post({
                    'round.wait': {}
                }, true
            );
        }

        function roundSleep() {
            outsmart_api.updateRequestTracker(['round.wait']);
        }


        /*
         * Only happens as indication of reveal phase starting
         * Could happen as result of round.getAnswer call, not currently used
         * */
        function answerResult(event, data) {

            outsmart_api.updateRequestTracker(['round.wait']);

            $scope.data.stage = 'reveal';

            updateTurn(data);

            if (!$scope.state.answersSubmitted) {
                //timeout on answer submission
                return;
            }

            $scope.revealOptions = data.answers;

        }

        /**
         * Another player revealed
         * Server could also have auto revealed for us.
         */
        function roundUpdate(event, data) {
            outsmart_api.updateRequestTracker(['round.wait']);
            updateTurn(data);
        }

        /**
         * We revealed, round.update triggered on auto reveal
         */
        function revealResult(event, data) {
            outsmart_api.updateRequestTracker(['round.wait']); // shouldn't be needed, think this is a direct response

            data.player_id = authService.data.user.player_id; //reveal result doesn't have player id, assumed to be self

            updateTurn(data);
        }

        function updateTurn(data) {
            $scope.data.isPlayersTurn = (data.next_player == authService.data.user.player_id);
            $scope.data.currentPlayer = data.next_player;
        }

        function roundEnd(event, data) {
            outsmart_api.updateRequestTracker(['round.wait']);

            for(var player in data.players) {
                player = data.players[player];
                match.data.players[player.player_id].score = player.total_score;
                match.data.players[player.player_id].pointsGained = player.score;
            }

            $state.go('match');
        }
    }

})();
