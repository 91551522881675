(function() {
    "use strict";
    angular.module('register').config(['$stateProvider', config]);

    function config($stateProvider) {
        $stateProvider.state("register", {
            url: "/register",
            templateUrl: "components/register/register.template.html",
            controller: "registerController as register"
        });
    }

})();
