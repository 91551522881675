(function() {
    "use strict";

    angular.module('chatModule').controller("chatController", ['$rootScope', '$scope', 'outsmart_api', chatController]);

    function chatController($rootScope, $scope, outsmart_api) {

        $scope.chat = {
            chatEntry: '',
            chatContent: []
        };

        var stopRoomUpdateListener = $rootScope.$on('room.chatResult', receivedChatUpdate);
        var stopGameUpdateListener = $rootScope.$on('game.chatResult', receivedChatUpdate);

        $scope.$on("$destroy", function destroy() {
                stopRoomUpdateListener();
                stopGameUpdateListener();
            }
        );

        var bindings = {
            keyboard: {
                13: submit
            }
        };

        $scope.keypress = function keypress(event) {
            var keyCode = event.keyCode;
            if (typeof bindings.keyboard[keyCode] !== 'undefined') {
                bindings.keyboard[keyCode]();
            }
        };

        function submit() {
            outsmart_api.post({
                    'room.chat': {
                        room_id: $scope.room.room_id,
                        message_text: $scope.chat.chatEntry
                    }
                }
            );
            $scope.chat.chatEntry = '';
        }

        function receivedChatUpdate(event, data) {
            outsmart_api.updateRequestTracker(['room.update']);
            outsmart_api.updateRequestTracker(['round.wait']);

            var color = 'blue';//getAssignedColour(data.player_id);

            $scope.chat.chatContent.push({
                player_id: data.player_id,
                player_username: data.player_username,
                message_text: data.message_text,
                color: color
            });

            //console.log($scope.chat.chatContent);

            //if (data.player_id !== c.data.user.player_id) {
            //    //c.announce('chat-update-other', data);
            //} else {
            //    //c.announce('chat-update-self', data);
            //}
        }

    }
})();