(function() {
    "use strict";

    angular.module("outsmart-services").service('updater',
        ["$interval", "outsmart_api", "outsmartConfig", updater]);

    function updater($interval, outsmart_api, outsmartConfig) {

        return {
            requests: {
                match: function() {
                    outsmart_api.post({
                        'game.wait': {}
                    }, true);
                },
                matchmaker: function() {
                    outsmart_api.post({
                        'room.update': {}
                    }, true);
                },
                round: function() {
                    outsmart_api.post({
                        'round.wait': {}
                    }, true);
                },
                wait: function() {
                    outsmart_api.post({
                        'login.wait': {}
                    }, true);
                }
            },
            stop: function(instance) {
                if (angular.isDefined(instance)) {
                    $interval.cancel(instance);
                }
            },
            start: function(request) {
                return $interval(this.requests[request], outsmartConfig.updateIntervals[request]);
            }
        };

    }

})();
