(function() {
    "use strict";

    angular.module("roundModule").service("roundService",
        ['outsmart_api', roundService]);

    function roundService(outsmart_api) {
        return {
            submitAnswers: function(gameId, roundId, answers) {
                outsmart_api.post({
                        'round.answer': {
                            game_id: gameId,
                            round_id: roundId,
                            answer_text: answers
                        }
                    }
                );
            },
            revealAnswer: function(gameId, roundId, sequence) {
                outsmart_api.post({
                    'round.reveal': {
                        game_id: gameId,
                        round_id: roundId,
                        sequence: sequence
                    }
                });
            }
        }
    }

})();
