(function() {
    "use strict";
    angular.module("match-players", [])
        .directive("matchPlayers", function() {
            return {
                restrict: 'E',
                controller: 'matchPlayersController as matchPlayers',
                bindToController: true,
                replace: true,
                scope: {},
                templateUrl: "components/match-players/match-players.template.html",
                link: function(scope, element, attributes, controller) {
                    controller.currentPlayer = scope.$parent.data.currentPlayer;
                }
            }
        });
})();
