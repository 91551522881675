(function() {

    /*TODO take out outsmart-services dependency, only needed by sub-modules */
    angular.module("outsmart", ["ui.router", "chatModule", 'login',
        'logout', "matchmakingModule", "matchModule", "match-players", 'ngStorage',
        "roundModule", "menuModule", "ngAnimate", 'outsmart-services', "register", "toastr",
        'timer'])
        .run(['$rootScope', '$state', 'toastr', run]);

    function run($rootScope, $state, toastr) {

        $rootScope.Math = Math;

        $rootScope.$on('login.expired', function sessionExpired() {
            toastr.error('Your session has expired. Please login again.', 'Session Expired');
            $state.go("login"); //disabled for easier testing
        });
    }

})();


