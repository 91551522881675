(function() {
    "use strict";
    angular.module('login')
        .config(
            ['$stateProvider', config]);

    function config($stateProvider) {
        $stateProvider.state("login", {
            url: "/login",
            templateUrl: "components/login/login.template.html",
            controller: "loginController as login"
        });
    }

})();
