(function() {
    "use strict";
    angular.module("chatModule").directive("chatDirective", function() {
        return {
            restrict: 'E',
            replace: true,
            controller: "chatController",
            templateUrl: "components/chat/chat.template.html"
        };
    });

})();