(function() {
    "use strict";
    angular.module("menuModule").directive("menuDirective", function() {
        return {
            restrict: 'E',
            replace: true,
            controller: "menuController",
            templateUrl: "components/menu/menu.template.html"
        };
    });

})();