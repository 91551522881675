(function() {
    "use strict";

    /**
     * @name outsmart.loginController
     */
    angular.module('login').controller("loginController", ['$scope',
        '$state', 'authService', 'toastr', loginController
    ]);

    function loginController($scope, $state, authService, toastr) {

        const login = this;
        login.login = loginRequest;

        login.data = {
            userDetails: {
                username: '',
                password: ''
            }
        }

        $scope.$on('login.success', loginSuccess);
        $scope.$on('login.failure', loginFailure);

        if (authService.isAuthenticated()) {
            authService.logout();
        }

        login.testCredentials = {
            david: {
                username: "David",
                password: '12345678'
            },
            test: {
                username: "Test",
                password: '12345678'
            },
            test2: {
                username: "Test2",
                password: '12345678'
            }
        }

        function loginRequest(credentials) {
            authService.login(credentials);
        };

        function loginFailure(event, args) {
            toastr.error(args.message, 'Login Failure');
        }

        function loginSuccess(event, session) {
            authService.loginSuccess(session);
            $state.go("matchmaking");
        }


    }
})();
