angular.module("roundModule").factory("answerStamp", function() {
    return stampit({
        methods: {
            //A map of method names and bodies for delegation.
            update: function(data) {
                angular.extend(this, data);
            },
            set: function(field, value) {
                this[field] = value;
            },
            getData: function() {
                return this;
            },
            setStatus: function(status) {
                this.status = status;
            }
        },
        refs: {
            //A map of property names and values to be mixed into each new object.
        },
        init: function() {
            //A closure (function) used to create private data and privileged methods.
        },
        props: {
            //An object to be deeply cloned into each newly stamped object.
            status: 'available'
        }
    });
});