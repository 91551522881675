(function() {
    "use strict";

    angular.module('outsmart')
        .controller("registerController", ['authService', registerController]);

    function registerController(authService) {

        const register = this;
        register.request = request;

        register.data = {
            fields: {
                username: '',
                email: '',
                password: '',
                player_interests: []
            }
        }

        function request(details) {
            authService.register(details);
        }
    }
})();
