(function() {

    angular.module("logout")
        .controller("logoutButtonController", ['authService', '$state', logoutButtonController]);

    function logoutButtonController(authService, $state) {
        const button = this;
        button.click = logout;

        function logout() {
            authService.logout();
            $state.go('login');
        }
    }

})();
